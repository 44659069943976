body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Dalmatins";
  src: local("Dalmatins"),
    url("./Pages/Dalmatins.eot") format("eot"),
    url('./Pages/Dalmatins.eot?#iefix') format('embedded-opentype'),
    url("./Pages/Dalmatins.woff") format("woff"),
    url('./Pages/Dalmatins.ttf') format('truetype'),
    url("./Pages/Dalmatins.otf") format("otf");
  font-weight: normal;
}