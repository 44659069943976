.HomePage-container{
    width: 100vw;
    height: 100vh;
    position: relative;
    top: 50;
    left:50;

    @media screen and (max-width: 1440px) {
        width: 83vw;
    }

    @media screen and (max-width: 1300px) {
        width: 100vw;
    }

    @media screen and (max-width: 1024px) {
        width: 143vw;
    }

    @media screen and (max-width: 780px) {
        width: 170vw;
    }

    @media screen and (max-width: 700px){
        width: 325vw;
      }

    // overflow-y: hidden;

    .HomePage-contents{
        width: 100%;
        height: 100%;
        position: relative;


        .the-portfolio{
            position: absolute;
            top: 142px;
            left: 82px;

            img{
                width: 335px;
                height: 320px;

            }
        }
        .the-story{
            position: absolute;
            top: 370px;
            left: 290px;

            img{
                width: 234px;
                height: 227px;
            }

        }
        .the-logo{
            position: absolute;
            top: 181px;
            left: 396px;

            img{
                width: 237px;
                height: 188px;
            }

        }
        .the-learning{
            position: absolute;
            z-index:20;
            top: 134px;
            left: 646px;

            img{
                width: 186px;
                height: 423px;
            }

        }
        .middle-rectangle{
            position: absolute;
            top: 254px;
            left: 854px;
            z-index: 20;

            img{
                width: 148px;
                height: 127px;
            }
        }
        .rectangle-left{
            position: absolute;
            top: 58px;
            left: 297px;

            img{
                width: 294px;
                height: 132px;
            }

        }
        .rectangle-right{
            position: absolute;
            top: 142px;
            left: 585px;
            z-index: 30;

            img{
                width: 613px;
                height: 48px;
            }

        }
        .texture-left{
            position: absolute;
            top: 118px;
            left: 219px;
            z-index: 20;

            img{
                width: 323px;
                height: 54px;
            }
        }
        .texture-right{
            position: absolute;
            top: 158px;
            left: 559px;

            img{
                width: 590px;
                height: 46px;
            }
            
        }
        .the-shop{
            position: absolute;
            top: 213px;
            left: 867px;

            img{
                width: 174px;
                height: 149px;
            }

        }
        .vertical-texture-1{
            position: absolute;
            top: 337px;
            left: 891px;
            z-index: 30;
            
            img{
                width: 33px;
                height: 199px;
            }
        }
        .vertical-texture-2{
            position: absolute;
            top: 337px;
            left: 934px;
            z-index: 30;

            img{
                width: 33px;
                height: 199px;
            }

        }

        .custom-texture-109{
            position: absolute;
            top: 20px;
            left: 96px;

            img{
                width: 170px;
                height: 172px;
            }
           
        }
        .custom-texture-22{
            position: absolute;
            top: 136px;
            left: 69px;
            z-index: 20;

            img{
                width: 364px;
                height: 342px;
            }

        }
        .custom-texture-106{
            position: absolute;
            top: 437px;
            left: 299px;

            z-index: 30;

            img{
                width: 296px;
                height: 159px;
            }
        }
        .custom-texture-90{
            position: absolute;
            top: 153px;
            left: 433px;
            z-index: 30;


            img{
                width: 196px;
                height: 39px;
            }
        }
        .custom-texture-102{
            position: absolute;
            top: 312px;
            left: 952px;

            img{
                width: 135px;
                height: 125px;
            }

        }
        

    }
    
}


