.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  font-family: 'Montserrat', sans-serif;

  @media screen and (max-width: 1024px) {
    width: 140vw;
}

  @media screen and (max-width: 780px) {
    width: 180vw;
  }

  @media screen and (max-width: 700px){
    width: 321vw;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #e0e0e0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  background: url(background-light.jpg) no-repeat center center fixed; 
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;

  @media screen and (max-width: 1000px) {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-size:10px;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
