.ComingSoon-container{

    width: 80vw;
    height: 100vh;
    // border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;


    @media screen and (max-width: 500px) {
        width: 90vw;
    }

    .ComingSoon-contents{

        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        // background-color: white;
        height: 80%;
        width: 50%;
        margin: auto;
        

        @media screen and (max-width: 625px) {
            flex-direction: column;
            width: 70%;
        }

        @media screen and (max-width: 500px) {
            width: 85%;
            height: 100%;

        }

        @media screen and (max-width: 400px) {
            width: 90%;
            height: 100%;

        }
        
        
        .Left-section{

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // height: 50%;

            @media screen and (max-width: 1888px) {
                height: 85%;
            }

            @media screen and (max-width: 1400px) {
                height: 95%;
            }

            .social-media{
                width: 50%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin:auto;
                margin-top: 10rem;

                @media screen and (max-width: 1888px) {
                    margin-top: 2.5rem;
                }

                @media screen and (max-width: 1363px) {
                    margin-top: 0.5rem;
                }

                @media screen and (max-width: 1363px) {
                    margin-top: 0.6rem;
                }

                @media screen and (max-width: 500px) {
                    margin-top: 7rem;
                }

                @media screen and (max-width: 380px) {
                    margin-top: 2rem;
                }

                .icon{
                    font-size: 3rem;
                    color: white;

                    @media screen and (max-width: 1888px) {
                        font-size: 2rem;
                    }

                    @media screen and (max-width: 1363px) {
                        font-size: 1.5rem;
                    }

                    @media screen and (max-width: 1024px) {
                        font-size: 1.3rem;
                    }
                    
                    @media screen and (max-width: 500px) {
                        font-size: 1.5rem;
                    }
                }

              
            }
            .subtitles{
                margin-top: 4rem;

                @media screen and (max-width: 1888px) {
                    margin-top: 2rem;
                }

                @media screen and (max-width: 1363px) {
                    margin-top: 1rem;
                }

                @media screen and (max-width: 500px) {
                    margin-top: 1.5rem;
                }
            
                .subtitle{

                   
                    font-size: 1rem;
                }

                #highlight{
                    background-color:white;
                    margin-bottom: 1rem;

                   
                }


                .subtitle2{
                    font-size: 1.5rem;
                    font-weight: 500;
                    font-style: normal;
                    font-family: essonnes-display,serif;

                    @media screen and (max-width: 1888px) {
                        font-size: 1.4rem;
                    }

                    @media screen and (max-width: 1363px) {
                        font-size: 1rem;
                    }

                    @media screen and (max-width: 500px) {
                        font-size: 1.2rem;
                   }

                    #bonus{
                        font-family: Dalmatins,sans-serif;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 2rem;
                        color: black;

                        @media screen and (max-width: 1888px) {
                            font-size: 1.6rem;
                        }

                        @media screen and (max-width: 1363px) {
                            font-size: 1.2rem;
                        }
                    }

                    @media screen and (max-width: 500px) {
                        font-size: 1.2rem;
                   }
                }
            }

            .rs-btn-primary{
                background-color: white;
                color: black;
                font-size: 1.2rem;

                font-weight: 500;
                font-style: normal;
                font-family: essonnes-display,serif;

                .rs-icon{

                    @media screen and (max-width: 1024px) {
                        font-size: 10px;
                    }
                }

                @media screen and (max-width: 1888px) {
                    font-size: 1rem;
                }

                @media screen and (max-width: 1363px) {
                    font-size: 0.8rem;
                }

                @media screen and (max-width: 1024px) {
                    font-size: 0.6rem;
                    padding: 7px 13px;
                }

                @media screen and (max-width: 500px) {
                    font-size: 1rem;
                    // padding: 7px 13px;
                }
            }

            .rs-input-group{
                border: none;
                border-radius: 0px;
                border-bottom: 3px solid white;
                width: 80%;
                margin: auto;
                font-family: essonnes-display,serif;

                @media screen and (max-width: 1363px) {
                    margin-top: 0px;
                    margin-bottom: 0px;

                }
            }
            .rs-input{
                background-color: transparent;
                font-size: 1.2rem;

                @media screen and (max-width: 1888px) {
                    font-size: 1rem;
                }

                @media screen and (max-width: 1363px) {
                    font-size: 0.8rem;
                }

                @media screen and (max-width: 1024px) {
                    font-size: 0.6rem;
                }

                @media screen and (max-width: 500px) {
                    font-size: 0.8rem;
                }

            }
            .logo{
                width: 20rem;
                margin:auto;

                @media screen and (max-width: 1888px) {
                    width: 15rem;
                }

                @media screen and (max-width: 1363px) {
                    width: 10rem;
                    margin-bottom: 0px;
                }

                @media screen and (max-width: 500px) {
                    width: 15rem;
                    margin-bottom: 0px;
                    margin-top: 1rem;
                }


            }
            .submit-button{
                margin-top: 0.5rem;
            }

            .title {
                font-size: 3rem;
                font-weight: 700;
                font-style: normal;
                font-family: essonnes-display,serif;
                text-transform: uppercase;
                letter-spacing: 1.1rem;

                @media screen and (max-width: 1888px) {
                    font-size: 1.8rem;
                    letter-spacing: 0.8rem;
                }

                @media screen and (max-width: 1363px) {
                    font-size: 1.3rem;
                    letter-spacing: 0.4rem;
                }

                @media screen and (max-width: 500px) {
                    font-size: 2rem;
                    letter-spacing: 0.3rem;
                }

                @media screen and (max-width: 400px) {
                    font-size: 1.7rem;
                    letter-spacing: 0.3rem;
                }
  
            }

            .subtitle {
                font-size: 1.5rem;
                font-weight: 500;
                font-style: normal;
                font-family: essonnes-display,serif;

                @media screen and (max-width: 1888px) {
                    font-size: 1.3rem;
                }

                @media screen and (max-width: 1363px) {
                    font-size: 1rem;
                }

                @media screen and (max-width: 500px) {
                    font-size: 1.3rem;
                }
               
            }

            .Countdown-section{
                display: flex;
                flex-direction: row;
                justify-content: center;
                justify-content: space-between;
                width: 80%;
                margin: auto;

                @media screen and (max-width: 1363px) {
                    margin: auto;
                    margin-bottom: 1rem;
                    margin-top: 0.5rem;
                }

                @media screen and (max-width: 500px) {
                    margin: auto 2rem;
                    margin-top: 0.5rem;
                    margin-bottom: 1.5rem

                }

                
            }

            .Mini-Countdown{
                display: flex;
                flex-direction: column;
                font-weight: 500;
                font-style: normal;
                font-family: essonnes-display,serif;

                @media screen and (max-width: 1363px) {
                    font-size: 1rem;
                }

                @media screen and (max-width: 500px) {
                    font-size: 2rem;
                }

                .name {
                    margin-top: 0px;
                    font-size: 1rem;
                    font-weight: 300;

                }
            }
        }

        

        .Right-section{

            .image{
                width: 600px;

                @media screen and (max-width: 500px) {
                    width: 270px;
                }
            }
            
        }
    }
}