

.TheStory-container{

    width: 50vw;

    @media screen and (max-width: 1200px) {
        width: 100vw !important;
    }

    .TheStory-contents{

        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 5rem;

        @media screen and (max-width: 1200px) {
            width: 100vw;
        }
    
        #in-content{
            font-family: Dalmatins,sans-serif;
            font-weight: 200;
            font-style: normal;
            font-size: 0.5rem;
            color: black;
        }
       
        
        .Section-container{
            display: flex;
            flex-direction: column;

            text-align: left;
            width: 40%;
            z-index: 10;

            @media screen and (max-width: 1200px) {
                margin-left: 0;
                width: 100%;
                margin-top: 7rem;

            }

        }

        .Section-container-right{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            text-align: left;
            margin-left:auto;
            height: 20vh;
            width: 40%;
            z-index: 10;

            @media screen and (max-width: 1200px) {
                margin-left: 0;
                width: 100%;
                margin-top: 7rem;

            }
            
        }

        #title{
            font-size: 1rem;

            font-family: Dalmatins,sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 2rem;
            color: black;
        }

        #contents{
            font-size: 1.2rem;
            margin-top: 2rem;
            font-weight: 300;
            font-style: normal;
            font-family: essonnes-display,serif;
        }

        .Section-container-center{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            height: 20vh;
            width: 100%;
            margin-top: 20vh;
        }

        .custom-texture-109{
            position: absolute;
            top: 1200px;
            left: 50vw;

            img{
                width: 250px;
                height: 250px;
            }
           
        }
        .custom-texture-22{
            position: absolute;
            top: 300px;
            left:  20vw;
            z-index: 5;

            img{
                width: 364px;
                height: 342px;
            }

        }
        .custom-texture-106{
            position: absolute;
            top: 437px;
            left: 60vw;

            z-index: 5;

            img{
                width: 296px;
                height: 159px;
            }
        }
        .custom-texture-90{
            position: absolute;
            top: 153px;
            left: 600px;
            z-index: 5;


            img{
                width: 196px;
                height: 39px;
            }
        }
        .custom-texture-102{
            position: absolute;
            top: 1120px;
            left: 952px;

            img{
                width: 135px;
                height: 125px;
            }

        }

    }
}